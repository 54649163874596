.teams {
    padding: 116px 0 75px;
}

.teams-single {
    padding: 116px 0 120px;
}

.teams-two {
    padding-top: 116px;
    padding-bottom: 120px;

    .scroll-circle {
        right: 0;
        left: auto;
        top: 25%;
        z-index: -1;
    }
}

.team-member {
    margin-bottom: 70px;
    max-width: 370px;
    margin: 0 auto 70px;

    .member-avater {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        svg {
            position: absolute;
            bottom: -100%;
            left: 0;
            transition: all 0.6s ease-in-out;

            &.layer-two {
                transition-delay: 0.3s;
            }

            &:hover {
                bottom: 0;
            }
        }


        .member-social {
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 333;
            opacity: 0;
            transition: all 0.4s ease-in-out;
            transition-delay: 0.3s;

            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    display: inline-block;
                    height: 46px;
                    width: 46px;
                    line-height: 46px;
                    text-align: center;
                    color: #fff;
                    border: 2px solid #fff;
                    border-radius: 50%;

                    &:hover {
                        background: #fff;
                        color: $color__theme;
                        box-shadow: 0px 20px 20px 0px rgba(79, 35, 35, 0.2);
                    }
                }
            }
        }

        &:hover {
            svg {
                bottom: 0;
            }

            .member-social {
                bottom: 40px;
                opacity: 1;
            }
        }
    }

    .team-info {
        text-align: center;
        padding-top: 25px;

        .name {
            font-size: 20px;
            font-weight: 600;
        }

        .job {
            font-size: 16px;
            color: #797687;
            font-weight: 400;
        }
    }
}

.image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1920px;
    height: 5976px;
    z-index: 242;
}

// Team Three

.teams-three {
	padding-bottom: 110px ;
}

.team-member-two {
    position: relative;
    border-radius: 6px;
	overflow: hidden;
	margin-bottom: 10px;

    img {
        width: 100%;
        transition: all 0.5s ease-in-out;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $color__theme-2;
        opacity: 0.9;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
    }

    .team-info {
        position: absolute;
        top: 40px;
        left: 40px;
		z-index: 22;
		height: 50px;
		overflow: hidden;

        .name {
            color: #fff;
            font-size: 20px;
			font-weight: 500;
			transition: all 0.5s ease-in-out;
			transform: translateY(-25px)
        }

        .job {
            color: #fff;
			font-size: 14px;
			font-weight: 400;
			transition: all 0.5s ease-in-out;
			transform: translateY(20px)
        }
    }

    .member-social {
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        left: 40px;
        bottom: 40px;
		z-index: 22;
		height: 28px;
		overflow: hidden;

        li {
            display: inline-block;
			margin-right: 15px;
			transform: translateY(20px);
			opacity: 0;
			transition: 0.3s ease-in-out;

			&:nth-child(2) {
				transition-delay: 0.1s;
			}

			&:nth-child(3) {
				transition-delay: 0.2s;
			}

			&:nth-child(4) {
				transition-delay: 0.3s;
			}

			&:nth-child(5) {
				transition-delay: 0.4s;
			}

            a {
                color: #fff;
            }
        }
	}
	
	&:hover {

		&:before {
			opacity: 0.9;
			visibility: visible;
		}
		.team-info {
			.name, .job {
				transform: translateY(0);
			}
		}

		.member-social {
			li {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}

@media (max-width: 991px) {

    .teams,
    .teams-two {
        padding: 76px 0 50px;
    }

    .teams-two {
        padding: 76px 0 80px;
    }

    .team-member {
        margin-bottom: 40px;
    }

    .section-small {
        margin: 0;
    }

    .teams-single {
        padding: 76px 0 80px;

        .button-container {
            margin-top: 10px;
        }
    }

    .teams-two {
        .scroll-circle {
            max-width: 320px;
        }
    }

    .teams-three {
        padding-bottom: 70px;
    }

}

@media (max-width: 768px) {

    .teams-two {
        .scroll-circle {
            max-width: 330px;
        }
    }
}

@media (max-width: 576px) {

    .scroll-circle {
        display: none;
    }
}