.portfolios {
    padding: 120px 0 40px;
}

.portfolios-two,
.portfolios-three {
    padding: 120px 0;
}

.pixsass-isotope-filter {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
        display: inline-block;
        margin-bottom: 10px;

        a {
            color: #797687;
            font-size: 16px;
            font-weight: 500;
            padding: 5px 23px;
            border: 1px solid transparent;
            border-radius: 30px;
            display: inline-block;
        }

        &.current {
            a {
                border-color: $color__theme;
                color: $color__theme;
            }
        }
    }
}

.grid-sizer {
    min-height: 1px;
}

.pixsass-portfolio-items {
    &.column-2 {
        width: calc(100% + 4%);

        .pixsass-portfolio-item,
        .grid-sizer {
            width: calc(50% - 4%);
            margin-right: 4%;
            float: left;

        }
    }

    &.column-3 {
        width: calc(100% + 2.4%);

        .pixsass-portfolio-item,
        .grid-sizer {
            width: calc(33.33% - 2.4%);
            margin-right: 2.4%;
            float: left;

        }
    }

    &.portfolio-one {

        .pixsass-portfolio-item {
            position: relative;
            margin-bottom: 150px;
            float: left;

            .portfolio-info {
                max-width: 420px;
                background: #fff;
                padding: 30px 40px;
                box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
                position: absolute;
                width: 100%;
                bottom: -70px;
                left: 40px;
                transition: all 0.3s ease-in-out;

                h3 {
                    font-size: 24px;
                    margin: 0;
                    line-height: 32px;

                    a {
                        color: $color__primary;

                        &:hover {
                            color: $color__theme;
                        }
                    }
                }

                span {
                    color: #797687;
                    font-size: 16px;
                }

            }

            &:hover {
                .portfolio-info {
                    box-shadow: none;
                    bottom: 40px;
                }
            }
        }
    }

    &.portfolio-two {

        .pixsass-portfolio-item {
            position: relative;
            margin-bottom: 60px;

            .pixsass-isotope-grid__img {
                position: relative;
                overflow: hidden;

                &:before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.2);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    z-index: 2;
                }

                img {
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }

                    img {
                        transform: scale(1.1)
                    }
                }
            }

            .portfolio-info {
                padding: 30px 40px 0;
                transition: all 0.3s ease-in-out;

                h3 {
                    font-size: 24px;
                    margin: 0;
                    line-height: 32px;

                    a {
                        color: $color__primary;

                        &:hover {
                            color: $color__theme;
                        }
                    }
                }

                span {
                    color: #797687;
                    font-size: 16px;
                }

            }

            &:hover {
                .portfolio-info {
                    box-shadow: none;
                }
            }
        }
    }

    &.portfolio-three {

        .pixsass-portfolio-item {
            position: relative;
            margin-bottom: 2%;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            img {
                transition: all 0.3s ease-in-out;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(-120deg, rgb(250, 151, 112) 0%, rgb(250, 112, 112) 100%);
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }

            .portfolio-info {
                padding: 35px 40px;
                box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0px;
                transition: all 0.3s ease-in-out;

                h3 {
                    font-size: 24px;
                    margin-bottom: 4px;
                    line-height: 32px;
                    transform: translateY(20px);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    transition-delay: 0.1s;


                    a {
                        color: #fff;
                    }
                }

                span {
                    color: #fff;
                    font-size: 14px;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    transform: translateY(20px);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;

                }

                p {
                    color: #fff;
                    margin: 0;
                    transform: translateY(20px);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    transition-delay: 0.3s;
                }

            }

            &:hover {

                &:before {
                    opacity: 0.9;
                    z-index: 3;
                }

                img {
                    transform: scale(1.1)
                }

                .portfolio-info {
                    box-shadow: none;
                    z-index: 4;

                    h3,
                    span,
                    p {
                        transform: translateY(0);
                        opacity: 1;
                    }

                }
            }
        }
    }
}

.portfolio-single {
    padding: 116px 0 100px;


}

.port-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;

    .portfolio-title {
        flex: 1;

        .title {
            max-width: 510px;
            font-size: 40px;
            line-height: 50px;
            font-weight: 500;
        }
    }

    .share-link {
        display: flex;
        align-items: center;

        .footer-social-link {
            margin: 0;
        }

        li a {
            background: transparent;
        }
    }
}

.portfolio-content {
    img {
        margin-bottom: 70px;
    }

    .content-inner {
        padding-right: 65px;
        border-right: 1px solid #efe7e7;

        .sub-title {
            font-size: 22px;
            font-weight: 500;
            line-height: 34px;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .portfolio-info {
        padding-left: 55px;



        .info {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 45px;
                color: $color__primary;

                span {
                    display: block;
                    font-size: 16px;
                    font-weight: 400;
                    color: #797687;
                    margin-top: 5px;
                }
            }
        }
    }
}

.portfolio-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    li {
        font-size: 20px;
        display: flex;
        align-items: end;

        i {
            font-size: 30px;
            transition: all 0.3s ease-in-out;
        }

        a {
            color: #797687;
        }

        &.prev {
            i {
                margin-right: 10px;
            }
        }

        &.next {
            i {
                margin-left: 10px;
            }
        }

        &:hover {

            i,
            a {
                color: $color__theme;
            }
        }
    }
}

.related-portfolio {
    padding-top: 138px;

    .portfolio-item {
        .feature-image {
            position: relative;

            img {
                width: 100%;
            }

            a {
                display: block;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.2);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }
            }

            &:hover {
                a {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        .port-info {
            padding: 25px 40px 0;

            h3 {
                font-size: 24px;
                margin-bottom: 3px;
                line-height: 32px;

                a {
                    color: #2b2350;

                    &:hover {
                        color: $color__theme;
                    }
                }
            }
        }
    }
}

#related-portfolio {
    .swiper-pagination {
        position: relative;
        margin-top: 55px;

        .swiper-pagination-bullet {
            height: 12px;
            width: 12px;
            transition: all 0.3s ease-in-out;
            margin-right: 5px;
            position: relative;
            opacity: 1;
            background: transparent;
            outline: 0;


            &:before {
                content: '';
                position: absolute;
                height: 12px;
                width: 12px;
                background: rgb(229, 214, 214);
                z-index: 2;
                top: 0;
                left: 0;
                border-radius: 50%;
                left: -2px;
                top: -2px;
                transition: all 0.3s ease-in-out;
                transform: scale(0.7)
            }

            &.swiper-pagination-bullet-active {
                opacity: 1;

                &:before {
                    opacity: 1;
                    transform: scale(1);
                    background: $color__theme;
                    box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
                }

            }
        }
    }
}

@media (max-width: 991px) {
    .portfolios {
        padding: 80px 0 50px;
    }

    .pixsass-isotope-filter {
        margin-bottom: 40px;
    }

    .portfolios-two,
    .portfolios-three {
        padding: 80px 0;
    }

    .pixsass-portfolio-items {

        &.portfolio-one {
            .pixsass-portfolio-item {
                margin-bottom: 120px;

                .portfolio-info {
                    max-width: 290px;
                    left: 20px;
                }
            }
        }

        &.column-3 {
            width: calc(100% + 3%);

            .pixsass-portfolio-item,
            .grid-sizer {
                width: calc(50% - 3%);
                margin-right: 3%;
                margin-bottom: 3%;

            }
        }
    }

    .portfolio-single {
        padding: 76px 0 80px;
    }

    .port-header {
        display: block;

        .portfolio-title .title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
        }
    }


}

@media (max-width: 768px) {
    .pixsass-portfolio-items {
        &.portfolio-one {
            .pixsass-portfolio-item {
                .portfolio-info {
                    max-width: 60%;
                    left: 20px;
                }
            }
        }

        &.column-2,
        &.column-3 {
            width: 100%;

            .pixsass-portfolio-item,
            .grid-sizer {
                width: 100%;
                margin-right: 0;
                margin-bottom: 30px;

                img {
                    width: 100%;
                }
            }
        }

        &.column-2 {
            .pixsass-portfolio-item {
                margin-bottom: 120px;
            }
        }
    }

    .portfolio-content {

        img {
            margin-bottom: 30px;
        }

        .content-inner {
            padding-right: 0;
            border-right: 0;
        }
    }

    .related-portfolio {
        padding-top: 60px;
    }

    .portfolio-content {
        .portfolio-info {
            padding-left: 0;
            padding-top: 30px;
        }
    }



    .portfolio-nav {
        margin-top: 30px;
    }
}

@media (max-width: 576px) {

    .pixsass-portfolio-items {
        &.column-2 {
            .pixsass-portfolio-item {
                margin-bottom: 80px;
            }
        }

        &.portfolio-one {

            .pixsass-portfolio-item {
                .portfolio-info {
                    max-width: 80%;
                    padding: 20px;
                    bottom: -50px;

                    h3 {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }

    }
}