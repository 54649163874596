#footer {
    background: $background__bg;

    .footer-inner {
        padding: 116px 0 62px;

    }

    .widget.footer-widget {
        .widget-title {
            color: #2b2350;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 38px;
            border-bottom: 0;
            padding-bottom: 0;
        }

        p {
            margin-bottom: 40px;
        }
    }

    .footer-menu {
        margin-bottom: 40px;
        padding: 0;
        list-style: none;

        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            a {
                display: block;
                color: #797687;
                font-weight: 400;
                line-height: 30px;

                &:hover {
                    color: $color__theme;
                }
            }
        }
    }

    .footer-social-link {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 10px;
            }

            a {
                height: 45px;
                width: 45px;
                line-height: 45px;
                border: 1px solid #e6dbdb;
                display: block;
                border-radius: 50%;
                color: #9694a1;
                text-align: center;
                font-size: 16px;

                &:hover {
                    background: $color__theme;
                    border-color: $color__theme;
                    color: #fff;
                    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
                }
            }
        }
    }

    .site-info {
        padding: 36px 0;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e4d9d9;
        position: relative;
        z-index: 3;

        p {
            margin: 0;

            a {
                color: $color__theme;
                font-weight: 500;

                &:hover {
                    color: darken($color__theme, 10%);
                }
            }
        }

    }

    &.footer-two {
        background: $background__2;

        .footer-menu {
            li {
                a {
                    &:hover {
                        color: $color__theme-2;
                    }
                }
            }
        }

        .footer-social-link {
            li {
                a {
                    &:hover {
                        background: $color__theme-2;
                        border-color: $color__theme-2;
                        box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
                    }
                }
            }
        }

        .site-info {
            border-color: #dad8e3;

            p {
                a {
                    color: $color__theme-2;

                    &:hover {
                        color: darken($color__theme-2, 10%);
                    }
                }
            }

        }

        .footer-social-link {
            li {
                a {
                    border-color: #dad8e3;
                }
            }
        }

        .site-info-menu li a:hover {
            color: $color__theme-2;
        }
    }

    &.footer-three {
        position: relative;

        .footer-inner {
            position: relative;
            z-index: 3;
        }

        .map-bg {
            text-align: center;
            position: absolute;
            top: 30px;
            left: 0;
            z-index: 0;
            width: 100%;
        }
    }

    &.footer-four {
        .site-info {
            background: #f8f2f2;
            border-top: 1px solid #e4d9d9;
            padding: 25px 0;

            .site-info-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &.footer-five {
        background: #2c234d;

        .widget {

            &.footer-widget {
                .widget-title {
                    color: #fff
                }
            }

            p {
                color: #a39eb9;
            }
        }

        .footer-menu {
            li {
                a {
                    color: #a39eb9;

                    &:hover {
                        color: #7052fb;
                    }
                }
            }
        }

        .footer-social-link {
            li {
                a {
                    border-color: #615a7e;
                    color: #a39eb9;

                    &:hover {
                        background: $color__theme-2;
                        box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
                        border-color: $color__theme-2;
                        color: #fff;
                    }
                }
            }
        }

        .site-info-menu li a {
            color: #9c99a7;

            &:hover {
                color: $color__theme-2
            }
        }

        .site-info {
            border-color: rgb(61, 53, 91);

            p {
                color: #9c99a7;

                a {
                    color: $color__theme-2;
                }
            }
        }
    }

    &.footer-tax-service {
        background: #f2f7fe;
        position: relative;

        .footer-inner {
            padding: 116px 0 175px;
        }

        .footer-logo {
            display: block;
            margin-bottom: 30px;
        }

        .email-info {
            display: block;
            color: $color__theme-4;
            margin-bottom: 20px;

            i {
                margin-right: 10px;
            }
        }

        .copyright-text {
            margin-bottom: 50px;

            a {
                color: $color__theme-4;
            }
        }

        .footer-social-link {
            li {
                a {
                    border-color: #d6dee9;
                    color: #9198a4;

                    &:hover {
                        background: $color__theme-4;
                        box-shadow: 0px 20px 30px 0px rgba(235, 56, 160, 0.3);
                        color: #fff;
                    }
                }
            }
        }

        .footer-menu {
            li {
                a {
                    color: #505064;

                    &:hover {
                        color: $color__theme-4;
                    }
                }
            }
        }

        .footer-bottom-element {
            .shape-element {
                margin: 0;
                padding: 0;
                list-style: none;
                width: 100%;

                li {
                    position: absolute;
                    bottom: 0;

                    &:nth-child(2) {
                        bottom: 0;
                        left: 70px;
                  
                    }

                    &:nth-child(3) {
                        bottom: 0;
                        left: 20px;
                    }

                    &:nth-child(4) {
                        bottom: 20px;
                        left: 40%;
                    }
                    &:nth-child(5) {
                        bottom: 0;
                        right: 120px;
                    }
                    &:nth-child(6) {
                        bottom: 0;
                        left: 200px;
                    }
                }
            }
        }
    }
}



.site-info-menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;

        a {
            display: inline-block;
            color: #797687;

            &:hover {
                color: $color__theme;
            }
        }
    }
}

@media (max-width: 991px) {
    #footer {
        .footer-inner {
            padding: 76px 0 22px;
        }

        .site-info {
            padding: 21px 0;
        }
    }

    #footer {
        .widget {

            &.footer-widget {
                margin-bottom: 30px;
                .widget-title {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #footer {
        .footer-menu {
            margin-bottom: 40px;
        }



        .site-info {
            padding: 21px 0;
            display: block;
            text-align: center;
        }

        &.footer-four {

            .copyright {
                margin-bottom: 20px;
            }

            .site-info {

                .site-info-inner {
                    display: block;
                }
            }
        }
    }
}