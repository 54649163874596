/*--------------------------------------------------------------
  ##  Page Loader
  --------------------------------------------------------------*/
@import "page-loader";

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  Section Title
  --------------------------------------------------------------*/
@import "section-title";

/*--------------------------------------------------------------
  ##  Icon Box
  --------------------------------------------------------------*/
@import "icon-box";

/*--------------------------------------------------------------
  ##  Editor
  --------------------------------------------------------------*/
@import "editor";

/*--------------------------------------------------------------
  ##  Genera Informes
  --------------------------------------------------------------*/
@import "genera-informes";

/*--------------------------------------------------------------
  ##  Revolutionize
  --------------------------------------------------------------*/
@import "revolutionize";

/*--------------------------------------------------------------
  ##  Inspect
  --------------------------------------------------------------*/
@import "inspect";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
  @import "testimonial";

/*--------------------------------------------------------------
  ##  Review Slider
  --------------------------------------------------------------*/
  @import "review-slider";

  /*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
  @import "pricing";

  /*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
  @import "team";

/*--------------------------------------------------------------
  ##  Faq's
  --------------------------------------------------------------*/
  @import "faq";

  /*--------------------------------------------------------------
  ##  Logo Carousel
  --------------------------------------------------------------*/
  @import "logo-carousel";

/*--------------------------------------------------------------
  ##  Call To Action
  --------------------------------------------------------------*/
  @import "call-to-action";

  /*--------------------------------------------------------------
  ##  Funfact
  --------------------------------------------------------------*/
  @import "fun-fact";

  /*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
  @import "newsletter";

  /*--------------------------------------------------------------
  ##  Page Banner
  --------------------------------------------------------------*/
  @import "page-banner";

  /*--------------------------------------------------------------
  ## About
  --------------------------------------------------------------*/
  @import "about";

  /*--------------------------------------------------------------
  ## Service
  --------------------------------------------------------------*/
  @import "service";

  /*--------------------------------------------------------------
  ## Contact Us
  --------------------------------------------------------------*/
  @import "contact-us";

  /*--------------------------------------------------------------
  ## Portfolio
  --------------------------------------------------------------*/
  @import "portfolio";

  /*--------------------------------------------------------------
  ## Download
  --------------------------------------------------------------*/
  @import "download";

  /*--------------------------------------------------------------
  ## Interface
  --------------------------------------------------------------*/
  @import "interface";

  /*--------------------------------------------------------------
  ## Call Back
  --------------------------------------------------------------*/
  @import "call-back";

  /*--------------------------------------------------------------
  ## Bookings Tabs
  --------------------------------------------------------------*/
  @import "bookings-tabs";

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
@import "blog";

/*--------------------------------------------------------------
  ##  Widget
  --------------------------------------------------------------*/
@import "widget";

/*--------------------------------------------------------------
  ##  Sign
  --------------------------------------------------------------*/
@import "signin";

/*--------------------------------------------------------------
  ##  Error
  --------------------------------------------------------------*/
@import "error";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";